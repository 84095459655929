var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quantity-input inline-flex border rounded-2xl border-pfm-grey-400 relative",class:[
    _vm.readonly
      ? 'pointer-events-none bg-black bg-opacity-10 opacity-60'
      : 'bg-white',
    {
      'w-full': _vm.block,
      'pr-5 h-10': _vm.contronlPosition !== 'default',
      'h-14': _vm.contronlPosition === 'default',
    } ]},[(!_vm.readonly)?_c('button',{staticClass:"btn flex items-center justify-center flex-shrink-0",class:[
      _vm.contronlPosition === 'default'
        ? 'p-5 w-14 h-14 bg-pfm-primary rounded-l-lg'
        : 'w-5 h-5 bg-pfm-primary absolute bottom-0 right-0 border-t border-white rounded-br-lg' ],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDecrease.apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"text-white",class:[_vm.contronlPosition === 'default' ? 'w-5 h-5' : 'w-3 h-3'],attrs:{"name":_vm.contronlPosition === 'default' ? 'pfm-remove-circle' : 'pfm-remove'}})],1):_vm._e(),_vm._v(" "),_c('input',{ref:"inputEl",staticClass:"focus:outline-none appearance-none-number text-center w-full outline-none bg-transparent p-2",class:[
      _vm.contronlPosition === 'default'
        ? 'h-14'
        : 'h-10' ],attrs:{"type":_vm.inputType,"maxlength":_vm.maxLength,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":function($event){_vm.isFocus = false},"change":function($event){_vm.check(Number($event.target.value))},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.onIncrease.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.onDecrease.apply(null, arguments)}]}}),_vm._v(" "),(!_vm.readonly)?_c('button',{staticClass:"btn flex items-center justify-center flex-shrink-0",class:[
      _vm.contronlPosition === 'default'
        ? 'p-5 w-14 h-14 bg-pfm-primary rounded-r-lg'
        : 'w-5 h-5 bg-pfm-primary absolute top-0 right-0 border-b border-white rounded-tr-lg' ],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onIncrease.apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"text-white",class:[_vm.contronlPosition === 'default' ? 'w-5 h-5' : 'w-3 h-3'],attrs:{"name":_vm.contronlPosition === 'default' ? 'pfm-add-circle' : 'pfm-add'}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }